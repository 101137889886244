import "./Header.scss";
import { Profile } from "./Profile/Profile";
import { useEffect, useState } from "react";
import { Nav } from "./Nav/Nav";
import { Social } from "./Social/Social";
import { DownloadCV } from "./DownloadCV/DownloadCV";
import { menuToggleSubject$ } from "../../services/menu.service";
import { tap } from "rxjs";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFirstToggle, setIsFirstToggle] = useState(true);

  useEffect(() => {
    const sub = menuToggleSubject$
      .pipe(
        tap(() => {
          if (isFirstToggle) {
            setIsFirstToggle(false);
          }
        })
      )
      .subscribe((isOpen) => setIsMenuOpen(isOpen));

      return () => sub.unsubscribe();
  });

  const toggleMenu = (isOpen: boolean) => menuToggleSubject$.next(isOpen);

  const menuCss = () => {
    if (isFirstToggle) {
      return "";
    } else {
      return isMenuOpen ? "in" : "out";
    }
  };

  return (
    <header className="fixed z-40 flex justify-center align-center bg-neutral-900 md:bg-neutral-900/[.5]">
      <button
        className={`z-50 block md:hidden fixed top-0 right-0 hamburger hamburger--emphatic ${
          isMenuOpen ? "is-active" : ""
        }`}
        onClick={() => toggleMenu(!isMenuOpen)}
        aria-label="Menu Toggle"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div
        className={`z-4 w-screen md:w-80 h-screen flex flex-col align-center justify-center bg-neutral-900 md:bg-transparent menu ${menuCss()}`}
      >
        <div className="header-component">
          <Profile />
        </div>
        <div className="header-component">
          <Nav />
        </div>
        <div className="header-component">
          <Social />
        </div>
        <div className="mx-auto my-5">
          <DownloadCV />
        </div>
        <div className="header-component text-xs text-center md:p-3">
          &copy; {new Date().getFullYear()} All Rights Reserved.
        </div>
      </div>
    </header>
  );
};
