import './Loader.scss';

export const Loader = () => {

  return (
      <div className='flex justify-center'>
        <div className="loader"></div>
      </div>
  );
};
