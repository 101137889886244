import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import "./TypingAnimation.scss";

const enum Direction {
  Forward,
  Backward,
}

const TypingAnimation = ({ words }: TypingAnimationProps) => {
  const [word, setWord] = useState("");
  const [direction, setDirection] = useState(Direction.Forward);
  const [index, setIndex] = useState(0);
  const [sleepTick, setSleepTick] = useState(1);
  const maxSleepTicks = 8;

  const nextWordCallback = useCallback(() => {
    setDirection(Direction.Forward);
    const newIndex = index === words.length - 1 ? 0 : index + 1;
    setIndex(newIndex);
    setWord("");
    setSleepTick(1);
  }, [words, index]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currWord: string = words[index];
      if (word.length === 0 && direction === Direction.Forward) {
        // Grab the first character to get things started
        setWord(currWord.charAt(0));
      } else {
        if (currWord !== word) {
          // As long as the displayed word doesn't match with the current word
          if (direction === Direction.Forward) {
            // Get the next character if we're typing forward
            setWord(currWord.substring(0, word.length + 1));
          } else {
            // Take out the last letter if we're typing backward
            setWord(word.slice(0, -1));
            // Move to the next word when we're done backspacing
            if (word.length === 0) {
              nextWordCallback();
            }
          }
        } else {
          // Strings are equal
          if (sleepTick !== maxSleepTicks) {
            // Sleep here for a bit to let the usr absorb the word being displayed
            setSleepTick(sleepTick + 1);
          } else {
            if (word.length === 0 && direction === Direction.Backward) {
              // If we're out of letters to backspace, then move to the next word
              nextWordCallback();
            } else {
              // THe strings are equal, so start backspacing
              setDirection(Direction.Backward);
              // Initialize with the word minus the last letter
              setWord(currWord.slice(0, -1));
            }
          }
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [words, word, index, sleepTick, direction, nextWordCallback]);

  return (
    <div className="text-center">
      {word}
      <span className="cursor">|</span>
    </div>
  );
};
TypingAnimation.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string),
};

interface TypingAnimationProps {
  words: string[];
}

export default TypingAnimation;
