import "./App.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Header } from "./components/Header/Header";
import { Main } from "./components/Main/Main";

library.add(fas, far, fab);

export const App = () => (
  <div>
    <div className="md:w-80">
      <Header />
    </div>
    <div className="md:ml-80">
      <Main />
    </div>
  </div>
);
