import React, { ReactElement } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { Loader } from "../Loader/Loader";

export const LoadingTransition = ({
  isLoaded,
  error,
  disableLoader,
  hasDataCallback,
  children,
}: LoadingTransitionProps) => {
  const nodeRef = React.useRef(null);

  const Success = () => {
    return (
      <>
        {!isLoaded && !error && !disableLoader ? <Loader /> : null}
        {isLoaded && !error && hasDataCallback() ? children : null}
      </>
    );
  };

  const Error = () => null;

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={isLoaded}
      timeout={200}
      classNames="load-in"
    >
      <div ref={nodeRef}>{error ? <Error /> : <Success />}</div>
    </CSSTransition>
  );
};

LoadingTransition.propTypes = {
  isLoaded: PropTypes.bool,
  error: PropTypes.any,
  disableLoader: PropTypes.bool,
  children: PropTypes.element.isRequired,
  hasDataCallback: PropTypes.func,
};

interface LoadingTransitionProps {
  isLoaded: boolean;
  error: any;
  disableLoader?: boolean;
  children: ReactElement;
  hasDataCallback: () => boolean;
}
