import { ProfileImage } from "./ProfileImage/ProfileImage";
import { PersonalData } from "./PersonalData/PersonalData";

export const Profile = () => {
  return (
    <>
      <ProfileImage />
      <div className="md:pt-5 md:mt-5 mx-auto">
        <PersonalData />
      </div>
    </>
  );
};
