import { useState } from "react";
import { DataSection } from "../../Shared/DataSection/DataSection";
import TypingAnimation from "./TypingAnimation/TypingAnimation";

export const Home = () => {
  const [personalData, setPersonalData] = useState(null as any);

  const HomeWrapper = () => (
    <>
      <h1 className="text-5xl text-center">
        {personalData.name.first} {personalData.name.last}
      </h1>
      <div className="my-2">
        <TypingAnimation words={personalData.professionalTitles} />
      </div>
    </>
  );

  return (
    <DataSection
      apiEndpoint="personal.json"
      setStateCallback={setPersonalData}
      hasDataCallback={() => personalData}
    >
      <HomeWrapper />
    </DataSection>
  );
};
