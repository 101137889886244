import { useState } from "react";
import { Timeline } from "../../Shared/Timeline/Timeline";
import { DataSection } from "../../Shared/DataSection/DataSection";
import { Education as EducationModel } from "../../../services/models/education.model";

export const Education = () => {
  const [educations, setEducations] = useState([] as EducationModel[]);

  const leftComponent = (index: number) => {
    const education = educations[index];
    return (
      <>
        <p>{education.year}</p>
        <p className="text-sm font-bold">{education.school}</p>
      </>
    );
  };

  const rightComponent = (index: number) => {
    const education = educations[index];
    return (
      <>
        <p className="mb-2 font-bold">{education.degree}</p>
        <p>{education.major}</p>
      </>
    );
  };

  return (
    <DataSection
      apiEndpoint="education.json"
      setStateCallback={setEducations}
      hasDataCallback={() => !!educations?.length}
    >
      <Timeline
        items={educations}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
      />
    </DataSection>
  );
};
