import { menuToggleSubject$ } from "../../../../services/menu.service";
import { PROFILE_IMG_URL } from "../../../../services/profile-image";

export const ProfileImage = () => {
  const Image = () => (
    <a href="#home" onClick={() => menuToggleSubject$.next(false)}>
      <img
        src={PROFILE_IMG_URL}
        alt="Profile"
        className="rounded-full h-32 mx-auto"
      />
    </a>
  );

  return <Image />;
};
