import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import {App} from "./App";
import reportWebVitals from "./reportWebVitals";
import { menuToggleSubject$ } from "./services/menu.service";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Prevent scrolling when menu is opened
menuToggleSubject$.subscribe((isOpen) => {
  const body = document.getElementsByTagName("body")[0];
  isOpen
    ? body.classList.add("overflow-hidden")
    : body.classList.remove("overflow-hidden");
});
