import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { DataSection } from "../../Shared/DataSection/DataSection";

export const DownloadCV = () => {
  const [downloadUrl, setDownloadUrl] = useState(undefined);

  const Download = () => (
    <a
      href={downloadUrl}
      target="_blank"
      rel="noreferrer"
      className="border rounded-full p-2 hover:bg-neutral-600"
    >
      <span className="pr-2">
        <FontAwesomeIcon icon="cloud-arrow-down" />
      </span>
      Download CV
    </a>
  );

  return (
    <DataSection
      apiEndpoint="resumeDownloadUri.json"
      setStateCallback={setDownloadUrl}
      hasDataCallback={() => !!downloadUrl}
    >
      <Download />
    </DataSection>
  );
};
