import PropTypes from "prop-types";
import { ReactElement } from "react";
import "./Timeline.scss";

export const Timeline = ({
  items,
  leftComponent,
  rightComponent,
}: TimelineProps) => {
  return (
    <ul className="timeline">
      {items.map((item: any, index: number) => (
        <li key={`timeline_${index}`}>
          <div className="timeline-left">{leftComponent(index)}</div>
          <div className="timeline-divider"></div>
          <div className="timeline-right">{rightComponent(index)}</div>
        </li>
      ))}
    </ul>
  );
};

Timeline.propTypes = {
  items: PropTypes.array,
  leftComponent: PropTypes.func,
  rightComponent: PropTypes.func,
};

interface TimelineProps {
  items: any[];
  leftComponent: (index: number) => ReactElement;
  rightComponent: (index: number) => ReactElement;
}
