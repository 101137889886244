import "./Main.scss";
import { sections } from "../../services/sections";

export const Main = () => {
  return (
    <main>
      {sections.map((section) => (
        <section key={section.name} id={section.name}>
          {section.hideTitle ? null : <h2>{section.titleOverride || section.name}</h2>}
          <div className="section-component">
            <section.component key={section.name} {...{ name: section.name }} />
          </div>
        </section>
      ))}
    </main>
  );
};
