import { Timeline } from "../../Shared/Timeline/Timeline";
import { useState } from "react";
import { DataSection } from "../../Shared/DataSection/DataSection";
import { Experience as ExperienceModel } from "../../../services/models/experience.model";

export const Experience = () => {
  const [experiences, setExperiences] = useState([] as ExperienceModel[]);

  const leftComponent = (index: number) => {
    const experience = experiences[index];
    return (
      <>
        <p>
          {experience.beginDate} - {experience.endDate}
        </p>
        <p className="text-sm font-bold">{experience.company}</p>
      </>
    );
  };

  const rightComponent = (index: number) => {
    const experience = experiences[index];
    return (
      <>
        <p className="mb-2 font-bold">
          {experience.titles.map((title: string, xpIndex: number) => (
            <span key={xpIndex}>
              {xpIndex !== 0 ? <span> · </span> : null}
              {title}
            </span>
          ))}
        </p>
        <p>{experience.description}</p>
      </>
    );
  };

  return (
    <DataSection
      apiEndpoint="experience.json"
      setStateCallback={setExperiences}
      hasDataCallback={() => !!experiences?.length}
    >
      <Timeline
        items={experiences}
        leftComponent={leftComponent}
        rightComponent={rightComponent}
      />
    </DataSection>
  );
};
