import React from 'react';

let cache: Map<string, any> = new Map();

export const asyncFetchData = (
    url: string,
    setStateCallback: (value: React.SetStateAction<any>) => void,
    setIsLoadedCallback: (value: React.SetStateAction<boolean>) => void,
    setErrorCallback: (value: React.SetStateAction<any>) => void,
    postFetchCallback?: (context: any) => void) => {

    const fetchData = async (
        url: string,
        setStateCallback: (value: React.SetStateAction<any>,) => void,
        setIsLoadedCallback: (value: React.SetStateAction<boolean>) => void) => {

        let json = cache.get(url);
        if (!json) {
            const data = await fetch(url);
            json = await data.json();
            cache.set(url, json);
        }

        setStateCallback(json);
        setIsLoadedCallback(true);

        if (postFetchCallback) {
            postFetchCallback(json)
        }
    };

    fetchData(url, setStateCallback, setIsLoadedCallback).catch((error) => {
        setErrorCallback(error);
        setIsLoadedCallback(true);
    });
}