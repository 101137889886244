import { menuToggleSubject$ } from "../../../services/menu.service";
import { sections } from "../../../services/sections";

export const Nav = () => {
  return (
    <nav>
        <ul>
            {sections.map((section) => (
            <li key={section.name} className="my-1 sm:my-2 px-4 py-1 sm:py-2 last:mb-0 last:pb-0 sm:last:mb-2 sm:last:pb-2">
                <a href={`#${section.name}`} className="block text-center hover:text-white hover:underline" onClick={() => menuToggleSubject$.next(false)}>
                  {section.displayName || section.name}
                </a>
            </li>
            ))}
        </ul>
    </nav>
  );
};
