import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "@react-hook/media-query";
import { useState } from "react";
import { DataSection } from "../../Shared/DataSection/DataSection";
import { Social as SocialModel } from "../../../services/models/social.model";

export const Social = () => {
  const matches = useMediaQuery("(min-width: 768px)");

  const [socials, setSocials] = useState([] as SocialModel[]);

  const nameFromOutlet = (outlet: string) => outlet.split("-")[0];

  const Socials = () => (
    <>
      {socials.map((social) => (
        <a
          key={social.outlet}
          href={social.url}
          target="_blank"
          rel="noreferrer"
          className="p-3 md:p-1 md:m-2 border rounded-full border-transparent hover:bg-neutral-600"
          aria-label={nameFromOutlet(social.outlet)}
        >
          <FontAwesomeIcon
            icon={["fab", social.outlet as IconName]}
            size={matches ? "1x" : "lg"}
            fixedWidth={true}
          />
        </a>
      ))}
    </>
  );

  return (
    <DataSection
      apiEndpoint="social.json"
      setStateCallback={setSocials}
      hasDataCallback={() => !!socials?.length}
    >
      <Socials />
    </DataSection>
  );
};
