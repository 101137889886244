import { useState } from "react";
import { DataSection } from "../../../Shared/DataSection/DataSection";
import { Personal } from "../../../../services/models/personal-data.model";

export const PersonalData = () => {
  const [personalData, setPersonalData] = useState({} as Personal);

  const PersonalData = () => (
    <>
      <h1 className="font-bold text-center text-xl">
        {personalData.name.first} {personalData.name.last}
      </h1>
      <div className="text-center mt-2 text-xs hover:underline hover:text-white">
        <a href={`mailto:${personalData.contact.email}`}>
          {personalData.contact.email}
        </a>
      </div>
      {/* <div className="text-center mx-auto">
        <a href={`tel:${personalData.contact.phone}`}>
          {personalData.contact.phone}
        </a>
      </div> */}
    </>
  );

  return (
    <DataSection
      apiEndpoint="personal.json"
      setStateCallback={setPersonalData}
      hasDataCallback={() => !!personalData?.name && !!personalData?.contact}
    >
      <PersonalData />
    </DataSection>
  );
};
