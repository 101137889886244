import { ReactElement, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useInViewport } from "react-in-viewport";
import { asyncFetchData } from "../../../services/fetch-data";
import { LoadingTransition } from "../Transition/Transition";

export const DataSection = ({
  apiEndpoint,
  setStateCallback,
  postFetchCallback,
  hasDataCallback,
  disableLoader,
  children,
}: DataSectionProps) => {
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/${apiEndpoint}`;

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(ref);

  useEffect(() => {
    if (!isLoaded && inViewport) {
      asyncFetchData(
        apiUrl,
        setStateCallback,
        setIsLoaded,
        setError,
        postFetchCallback
      );
    }
  }, [
    apiUrl,
    ref,
    inViewport,
    isLoaded,
    error,
    setStateCallback,
    postFetchCallback,
  ]);

  return (
    <div ref={ref}>
      <LoadingTransition
        isLoaded={isLoaded}
        error={error}
        disableLoader={disableLoader}
        hasDataCallback={hasDataCallback}
      >
        {children}
      </LoadingTransition>
    </div>
  );
};

DataSection.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  setStateCallback: PropTypes.func.isRequired,
  setErrorCallback: PropTypes.func,
  hasDataCallback: PropTypes.func.isRequired,
  postFetchCallback: PropTypes.func,
  children: PropTypes.element.isRequired,
  disableLoader: PropTypes.bool,
};

interface DataSectionProps {
  apiEndpoint: string;
  setStateCallback: (value: React.SetStateAction<any>) => void;
  setErrorCallback?: (value: React.SetStateAction<any>) => void;
  hasDataCallback: () => boolean;
  postFetchCallback?: (context: any) => void;
  children: ReactElement;
  disableLoader?: boolean;
}
