export interface Skill {
    category: string;
    displayName: string;
    icon: Icon,
    name: string
}

export interface Icon {
    name: string,
    type: IconType
}

export enum IconType {
    devicon = "devicon",
    devicons = "devicons",
    iconsfordevs = "iconsfordevs",
    custom = "custom"
}

export function resolveIcon(icon: Icon) {
    const icons: Record<IconType, string> = {
        [IconType.devicon]: `devicon-${icon.name}-plain`,
        [IconType.devicons]: `devicons devicons-${icon.name}`,
        [IconType.iconsfordevs]: `iconsfordevs-${icon.name}`,
        [IconType.custom]: ""
    };

    return icons[icon.type] || "";
}