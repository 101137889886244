import "./Skills.scss"
import { useState } from "react";
import { DataSection } from "../../Shared/DataSection/DataSection";
import { resolveIcon, Skill } from "../../../services/models/skills.model";

interface SkillIconProps {
  skill: Skill;
  index: number;
}

const CUSTOM_DEVICON = {
  path: "devicon",
  size: 36
}

const CustomSkillIcon = ({ skill, index }: SkillIconProps) => {
  return (
    <img className="custom-skills-icon" src={`${CUSTOM_DEVICON.path}/${skill.icon.name}.svg`} width={CUSTOM_DEVICON.size} height={CUSTOM_DEVICON.size} alt={skill.displayName}/>
  );
};

const SkillIcon = ({ skill, index }: SkillIconProps) => {
  return (
    <li
      className={`inline-block py-2 text-center ${index !== 0 ? "mx-2" : ""}`}
    >
      {skill.icon.type === "custom" ? <CustomSkillIcon skill={skill} index={index} /> : <i className={`text-4xl ${resolveIcon(skill.icon)} `}></i>}
      <p className="text-xs">{skill.displayName}</p>
    </li>
  );
};

interface CategorySkillProps {
  category: string;
  skills: Skill[];
}

const CategorySkill = ({ category, skills }: CategorySkillProps) => {
  return (
    <>
      <p className="text-lg underline underline-offset-8 decoration-neutral-400 mb-2">
        {category}
      </p>
      <ul>
        {skills.map((skill: Skill, index: number) => (
          <SkillIcon key={index} skill={skill} index={index} />
        ))}
      </ul>
    </>
  );
};

export const Skills = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skills, setSkills] = useState([] as Skill[]);
  const [categoryMap, setCategoryMap] = useState(new Map());

  const postFetchCallback = (skills: Skill[]) => {
    let newCategoryMap: Map<string, Skill[]> = new Map();

    skills.forEach((skill: Skill) => {
      let categoryInMap = newCategoryMap.get(skill.category);
      if (!categoryInMap) {
        newCategoryMap.set(skill.category, [skill]);
      } else {
        categoryInMap.push(skill);
        newCategoryMap.set(skill.category, categoryInMap);
      }
    });

    setCategoryMap(newCategoryMap);
  };

  const SkillData = () => (
    <div>
      {Array.from(categoryMap.keys()).map((category) => (
        <div className="my-5" key={category}>
          <CategorySkill
            category={category}
            skills={categoryMap.get(category)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <DataSection
      apiEndpoint="languagesTools.json"
      setStateCallback={setSkills}
      postFetchCallback={postFetchCallback}
      hasDataCallback={() => !!categoryMap?.size}
    >
      <SkillData />
    </DataSection>
  );
};
