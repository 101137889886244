import { useState } from "react";
import "./Interests.scss";
import { DataSection } from "../../Shared/DataSection/DataSection";

export const Interests = () => {
  const [interests, setInterests] = useState("");
  const InterestsData = () => (
    <p
      dangerouslySetInnerHTML={{ __html: interests }}
      className="interests"
    ></p>
  );

  return (
    <DataSection
      apiEndpoint="interests.json"
      setStateCallback={setInterests}
      hasDataCallback={() => !!interests?.length}
    >
      <InterestsData />
    </DataSection>
  );
};
