import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { DataSection } from "../../Shared/DataSection/DataSection";

interface ListProps {
  items: any[];
  iconColor: string;
  icon: string;
}

export const Certifications = () => {
  const [certifications, setCertifications] = useState([] as any[]);
  const [awards, setAwards] = useState([] as any[]);

  const List = ({ items, iconColor, icon }: ListProps) => (
    <>
      {items.map((item: string[], index: number) => (
        <li key={index} className="my-2">
          <span className={`pr-2 ${iconColor}`}>
            <FontAwesomeIcon icon={icon as IconProp} fixedWidth={true} />
          </span>
          <span>{item}</span>
        </li>
      ))}
    </>
  );

  return (
    <>
      <DataSection
        apiEndpoint="certifications.json"
        setStateCallback={setCertifications}
        hasDataCallback={() => !!certifications?.length}
      >
        <ul>
          <List
            icon="certificate"
            iconColor="text-amber-400"
            items={certifications}
          />
        </ul>
      </DataSection>
      <DataSection
        apiEndpoint="awards.json"
        setStateCallback={setAwards}
        hasDataCallback={() => !!awards?.length}
        disableLoader={true}
      >
        <ul>
          <List icon="award" iconColor="text-indigo-400" items={awards} />
        </ul>
      </DataSection>
    </>
  );
};
