import { Home } from "../components/Main/Home/Home";
import { Experience } from '../components/Main/Experience/Experience';
import { Education } from '../components/Main/Education/Education';
import { Skills } from '../components/Main/Skills/Skills';
import { Certifications } from '../components/Main/Certifications/Certifications';
import { Interests } from '../components/Main/Interests/Interests';


export const sections = [
    {
        name: "home",
        component: Home,
        hideTitle: true
    },
    {
        name: "experience",
        component: Experience
    },
    {
        name: "education",
        component: Education
    },
    {
        name: "skills",
        component: Skills
    },
    {
        name: "certifications",
        component: Certifications,
        titleOverride: "Certifications & Awards"
    },
    {
        name: "interests",
        displayName: "about me",
        titleOverride: "About Me",
        component: Interests
    },
    // {
    //     name: "contact",
    //     component: null
    // }
]